var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full p-10"},[_c('vs-row',{staticClass:"m-0 mb-4",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end","vs-w":"12"}},[_c('vs-input',{staticClass:"mx-1",attrs:{"color":"dark","type":"date","size":"small","label":"Data De"},model:{value:(_vm.filtro.dataDe),callback:function ($$v) {_vm.$set(_vm.filtro, "dataDe", $$v)},expression:"filtro.dataDe"}}),_c('vs-input',{staticClass:"mx-1",attrs:{"color":"dark","type":"date","label":"Data Até","size":"small"},model:{value:(_vm.filtro.dataAte),callback:function ($$v) {_vm.$set(_vm.filtro, "dataAte", $$v)},expression:"filtro.dataAte"}}),_c('el-button',{staticClass:"mx-1",attrs:{"size":"small","type":"primary","plain":""},on:{"click":function($event){return _vm.getAvaliacoes()}}},[_vm._v("FILTRAR")])],1)],1),_c('vs-row',{staticClass:"m-0",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('div',{staticClass:"cardTable w-full mb-1 vs-con-loading__container",attrs:{"id":"loading-avalicoes-pco"}},[(_vm.avaliacoes.length == 0)?_c('div',[_c('div',{staticClass:"pt-20 scroll-avaliacao-pco con-colors"},[_c('ul',{staticClass:"pt-20"},[_c('li',{staticClass:"danger-box"},[_c('h2',{staticClass:"p-5",staticStyle:{"color":"white !important"}},[_vm._v(" Nenhum Técnico ")])])])])]):_c('div',{staticClass:"w-full mt-1"},[_c('VuePerfectScrollbar',{staticClass:"scroll-avaliacao-pco",attrs:{"settings":_vm.settings}},[_c('vs-table',{attrs:{"stripe":"","data":_vm.avaliacoes,"max-items":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(row,index){return _c('vs-tr',{key:index,attrs:{"data":row}},[_c('vs-td',{staticClass:"text-center font-semibold"},[_vm._v(_vm._s(index + 1)+"°")]),_c('vs-td',[_vm._v(_vm._s(row.tecnico))]),_c('vs-td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(row.nota ? parseFloat(row.nota).toFixed(2) : 0))]),_vm._v(" de 5 ")]),_c('vs-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.pontos ? row.pontos : 0)+" ")]),_c('vs-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$currency(row.bonus)))]),_c('vs-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.horas ? row.horas : 0)+" ")]),_c('template',{staticClass:"expand-user",slot:"expand"},[_c('avaliacaoCard',{attrs:{"row":row,"filtro":_vm.filtro}})],1)],2)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{staticStyle:{"width":"5%"},attrs:{"id":"center"}},[_vm._v("Ranking")]),_c('vs-th',{staticStyle:{"width":"30%"}},[_vm._v("Técnico")]),_c('vs-th',{staticStyle:{"width":"18%"},attrs:{"id":"center"}},[_vm._v("Nota PCO")]),_c('vs-th',{staticStyle:{"width":"18%"},attrs:{"id":"center"}},[_vm._v("Total de Pontos")]),_c('vs-th',{staticStyle:{"width":"18%"},attrs:{"id":"center"}},[_vm._v("Total de Comissão")]),_c('vs-th',{staticStyle:{"width":"18%"},attrs:{"id":"center"}},[_vm._v("Horas Totais")])],1)],2)],1)],1)])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vx-card',{attrs:{"no-shadow":"","card-background":"dark"}},[_c('vs-row',[_c('vs-col',{staticClass:"py-1",attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"flex-start","vs-align":"center"}},[_c('Badge',{staticClass:"mr-2",attrs:{"color":"dark","text":'Colaboradores: ' + _vm.avaliacoes.length,"size":"large"}}),_c('Badge',{staticClass:"mr-2",attrs:{"color":"dark","text":'Média Pontos: ' +
                (_vm.avaliacoes.length > 0
                  ? (
                      _vm.avaliacoes.reduce(function (a, b) { return a + (b['pontos'] || 0); }, 0) /
                      (_vm.avaliacoes.length || 0)
                    ).toFixed(2)
                  : 0),"size":"large"}}),_c('Badge',{staticClass:"mr-2",attrs:{"color":"dark","text":'Nota Média: ' +
                (_vm.avaliacoes.length > 0
                  ? (
                      _vm.avaliacoes.reduce(function (a, b) { return a + (b['nota'] || 0); }, 0) /
                      (_vm.avaliacoes.length || 0)
                    ).toFixed(2)
                  : 0),"size":"large"}}),_c('Badge',{staticClass:"mr-2",attrs:{"color":"dark","text":'Comissão Média: ' +
                _vm.$currency(
                  parseFloat(
                    _vm.avaliacoes.reduce(function (a, b) { return a + (b['bonus'] || 0); }, 0) /
                      (_vm.avaliacoes.length || 0)
                  )
                ),"size":"large"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }