<template>
  <div class="w-full p-10">
    <vs-row
      vs-type="flex"
      class="m-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getAvaliacoes()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <vs-row
      vs-type="flex"
      class="m-0"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <div
          id="loading-avalicoes-pco"
          class="cardTable w-full mb-1 vs-con-loading__container"
        >
          <div v-if="avaliacoes.length == 0">
            <div class="pt-20 scroll-avaliacao-pco con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Técnico
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="w-full mt-1">
            <VuePerfectScrollbar
              class="scroll-avaliacao-pco"
              :settings="settings"
            >
              <vs-table stripe :data="avaliacoes" max-items="100">
                <template slot="thead">
                  <vs-th style="width: 5%" id="center">Ranking</vs-th>
                  <vs-th style="width: 30%">Técnico</vs-th>
                  <vs-th style="width: 18%" id="center">Nota PCO</vs-th>
                  <vs-th style="width: 18%" id="center">Total de Pontos</vs-th>
                  <vs-th style="width: 18%" id="center"
                    >Total de Comissão</vs-th
                  >
                  <vs-th style="width: 18%" id="center">Horas Totais</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :data="row" :key="index" v-for="(row, index) in data">
                    <vs-td class="text-center font-semibold"
                      >{{ index + 1 }}°</vs-td
                    >
                    <vs-td>{{ row.tecnico }}</vs-td>
                    <vs-td class="text-center">
                      <b>{{
                        row.nota ? parseFloat(row.nota).toFixed(2) : 0
                      }}</b>
                      de 5
                    </vs-td>
                    <vs-td class="text-center">
                      {{ row.pontos ? row.pontos : 0 }}
                    </vs-td>
                    <vs-td class="text-center">{{
                      $currency(row.bonus)
                    }}</vs-td>
                    <vs-td class="text-center">
                      {{ row.horas ? row.horas : 0 }}
                    </vs-td>
                    <template class="expand-user" slot="expand">
                      <avaliacaoCard :row="row" :filtro="filtro" />
                    </template>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vx-card no-shadow card-background="dark">
          <vs-row>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              class="py-1"
            >
              <Badge
                class="mr-2"
                color="dark"
                :text="'Colaboradores: ' + avaliacoes.length"
                size="large"
              ></Badge>
              <Badge
                class="mr-2"
                color="dark"
                :text="
                  'Média Pontos: ' +
                  (avaliacoes.length > 0
                    ? (
                        avaliacoes.reduce((a, b) => a + (b['pontos'] || 0), 0) /
                        (avaliacoes.length || 0)
                      ).toFixed(2)
                    : 0)
                "
                size="large"
              ></Badge>
              <Badge
                class="mr-2"
                color="dark"
                :text="
                  'Nota Média: ' +
                  (avaliacoes.length > 0
                    ? (
                        avaliacoes.reduce((a, b) => a + (b['nota'] || 0), 0) /
                        (avaliacoes.length || 0)
                      ).toFixed(2)
                    : 0)
                "
                size="large"
              ></Badge>
              <Badge
                class="mr-2"
                color="dark"
                :text="
                  'Comissão Média: ' +
                  $currency(
                    parseFloat(
                      avaliacoes.reduce((a, b) => a + (b['bonus'] || 0), 0) /
                        (avaliacoes.length || 0)
                    )
                  )
                "
                size="large"
              ></Badge>
            </vs-col>
          </vs-row>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import avaliacaoCard from "./components/avaliacaoCard";
export default {
  data() {
    return {
      filtro: { dataDe: null, dataAte: null },
      avaliacoes: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      atividades: [10, 18],
    };
  },
  methods: {
    async mountData() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0];
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0];
      this.filtro.dataDe = firstDay;
      this.filtro.dataAte = lastDay;
    },
    async getAvaliacoes() {
      await this.$vs.loading({
        container: "#loading-avalicoes-pco",
        scale: 0.6,
      });
      try {
        this.avaliacoes = await this.$http.post("getAvaliacaoPCO", {
          filtro: this.filtro,
          atividades: this.atividades,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loading-avalicoes-pco > .con-vs-loading"
        );
      }
    },
  },
  async mounted() {
    await this.mountData();
    await this.getAvaliacoes();
  },
  components: {
    ...components,
    avaliacaoCard,
  },
};
</script>
<style lang="scss">
.scroll-avaliacao-pco {
  height: 73vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
